var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c(
      "div",
      [
        _vm.loader ? _c("loader") : _vm._e(),
        _c(
          "b-row",
          { staticClass: "pb-4" },
          [
            _c("b-col", { attrs: { cols: "12" } }, [
              _c("h2", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("payments.manageTed.manageTedTitle")) +
                    " "
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "p",
          { staticClass: "ted-data" },
          [
            _vm._v(" Transferências pendentes "),
            _c("b-badge", { attrs: { variant: "info" } }, [
              _vm._v(" " + _vm._s(_vm.getQuantity) + " "),
            ]),
            _vm._v(" | Valor em transferências pendentes "),
            _c("b-badge", { attrs: { variant: "info" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.setPriceFormat(
                      _vm.$t("payments.labels.currency"),
                      _vm.getAmount
                    )
                  ) +
                  " "
              ),
            ]),
          ],
          1
        ),
        _c(
          "b-button",
          {
            staticClass: "select-all-btn",
            attrs: { variant: "primary" },
            on: { click: _vm.selectAllTeds },
          },
          [_vm._v(" " + _vm._s(_vm.$t("payments.manageTed.selectAll")) + " ")]
        ),
        _c(
          "b-button",
          {
            staticClass: "select-all-btn",
            attrs: {
              variant: "success",
              disabled: _vm.selectedTeds.length === 0,
            },
            on: { click: _vm.confirmBulkTed },
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("payments.manageTed.approveSelected")) + " "
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "table-pending-credit-request-list" },
          [
            _c("custom-data-table", {
              ref: "ted_table",
              attrs: {
                fields: _vm.fields,
                filters: _vm.filters,
                "per-page": 10,
                query: _vm.transfer_by_status,
                "query-variables": { status: "pending" },
                "query-node": "getTransferByStatus",
              },
              scopedSlots: _vm._u([
                {
                  key: "select",
                  fn: function (props) {
                    return _c(
                      "span",
                      {},
                      [
                        _c("b-form-checkbox", {
                          attrs: { checked: _vm.isSelected(props.rowData.id) },
                          on: {
                            input: function ($event) {
                              return _vm.selectTed($event, props.rowData)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "driver",
                  fn: function (props) {
                    return _c(
                      "span",
                      {},
                      [
                        _c(
                          "router-link",
                          {
                            directives: [
                              { name: "b-tooltip", rawName: "v-b-tooltip" },
                            ],
                            attrs: {
                              title: _vm.$t("payments.labels.goToDriver"),
                              to: `/drivers/profile/${props.rowData.driver.id}`,
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(props.rowData.driver.name) + " "
                            ),
                            _c("i", {
                              staticClass:
                                "fa fa-external-link icon-size-driver",
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  },
                },
                {
                  key: "created_at",
                  fn: function (props) {
                    return _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDateUtc(props.rowData.created_at)) +
                          " "
                      ),
                    ])
                  },
                },
                {
                  key: "bankAccount",
                  fn: function (props) {
                    return _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.setBankAccount(props.rowData.bank_account)
                          ) +
                          " "
                      ),
                    ])
                  },
                },
                {
                  key: "amount",
                  fn: function (props) {
                    return _c("span", {}, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.setPriceFormat(
                              _vm.$t("payments.labels.currency"),
                              props.rowData.amount
                            )
                          ) +
                          " "
                      ),
                    ])
                  },
                },
                {
                  key: "actions",
                  fn: function (props) {
                    return _c(
                      "span",
                      {},
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary", size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.goTo(props.rowData)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("payments.manageTed.modalTitle"))
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  },
                },
              ]),
            }),
            _c("modal-manage-ted", {
              attrs: { modal_data: _vm.modalData },
              on: { approve: _vm.approveCredit, reprove: _vm.reproveCredit },
              model: {
                value: _vm.modalCreditRequest,
                callback: function ($$v) {
                  _vm.modalCreditRequest = $$v
                },
                expression: "modalCreditRequest",
              },
            }),
            _c("modal-bulk-ted-status", {
              attrs: { modal_data: _vm.modalBulkTedData },
              on: { change: _vm.handleCloseBulkTedModal },
              model: {
                value: _vm.modalBulkTedStatus,
                callback: function ($$v) {
                  _vm.modalBulkTedStatus = $$v
                },
                expression: "modalBulkTedStatus",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }