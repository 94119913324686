var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "bulkTedStatus",
        lazy: "",
        "no-fade": "",
        size: "lg",
        "no-enforce-focus": "",
        title: "Status Aprovação TED",
        "cancel-title": _vm.$t("payments.buttons.close"),
      },
      on: { show: _vm.handleOpen, hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _c(
        "b-list-group",
        _vm._l(_vm.modal_data, function (ted, index) {
          return _c(
            "b-list-group-item",
            {
              key: index,
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("strong", [_vm._v(" Motorista: ")]),
              _c(
                "b-link",
                {
                  attrs: {
                    href: `/drivers/profile/${ted.driver.id}`,
                    target: "_blank",
                    title: _vm.$t("payments.labels.goToDriver"),
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-external-link icon-size-driver",
                  }),
                  _vm._v(" " + _vm._s(ted.driver.name) + " "),
                ]
              ),
              _c("strong", [_vm._v(" Valor: ")]),
              _vm._v(
                _vm._s(
                  _vm.setPriceFormat(
                    _vm.$t("payments.labels.currency"),
                    ted.amount
                  )
                ) + " "
              ),
              ted.success
                ? _c("b-badge", { attrs: { variant: "success", pill: "" } }, [
                    _vm._v("OK"),
                  ])
                : _c("b-badge", { attrs: { variant: "danger", pill: "" } }, [
                    _vm._v("Erro - " + _vm._s(ted.error)),
                  ]),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "ml-2",
              attrs: { variant: "success", title: "OK" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleClose.apply(null, arguments)
                },
              },
            },
            [_vm._v(" OK ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }