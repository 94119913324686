<template>
  <b-modal
    id="modalCreditRequest"
    lazy
    no-fade
    size="lg"
    no-enforce-focus
    :title="$t('payments.manageTed.modalTitle')"
    :cancel-title="$t('payments.buttons.close')"
    v-model="cpHandlerModal"
    @show="handleOpen"
    @hidden="handleClose"
  >
    <b-form name="driver" v-if="modal_data.driver">
      <h5 class="d-block pt-1 pb-2">
        {{ $t('payments.creditRequest.modalDriverDetailsTitle') }}
      </h5>
      <b-form-row>
        <b-form-group
          id="fg-driver-name"
          :label="$t('payments.creditRequest.labelDriverName')"
          label-for="driver-name"
          required
          class="col-md-6 col-sm-12"
        >
          <b-form-input
            id="driver-name"
            disabled
            :value="modal_data.driver.name"
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="fg-driver-name"
          label-for="driver-cpf"
          required
          class="col-md-6 col-sm-12"
        >
          <b-link
            :href="`/drivers/profile/${modal_data.driver.id}`"
            target="_blank"
            class="btn btn-primary btn-space"
            :title="$t('payments.labels.goToDriver')"
          >
            <i class="fa fa-external-link icon-size-driver" />
            {{ $t('payments.labels.goToDriver') }}
          </b-link>
        </b-form-group>
      </b-form-row>
      <hr>
      <h5 class="d-block pt-1 pb-2">
        {{ $t('payments.manageTed.amountTed') }}
      </h5>
      <b-form-row>
        <b-form-group
          id="fg-credit-value"
          :label="$t('payments.manageTed.amount')"
          label-for="credit-value"
          class="col-md-4 col-sm-12"
        >
          <b-form-input
            id="credit-value"
            disabled
            :value="setPriceFormat($t('payments.labels.currency'), modal_data.amount)"
            type="text"
          />
        </b-form-group>        
      </b-form-row>
    </b-form>

    <div slot="modal-footer">
      <b-button
        variant="light"
        @click.stop="handleClose"
      >
        {{ $t('payments.buttons.cancel') }}
      </b-button>

      <b-button
        v-b-tooltip.hover
        variant="danger"
        class="ml-2"
        :title="$t('payments.manageTed.reprove')"
        @click.stop="$emit('reprove', modal_data)"
      >
        {{ $t('payments.manageTed.reprove') }}
      </b-button>
      <b-button
        v-b-tooltip.hover
        variant="success"
        class="ml-2"
        :title="$t('payments.manageTed.approve')"
        @click.stop="$emit('approve', modal_data)"
      >
        {{ $t('payments.manageTed.approve') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { formatPrice } from '@utils/numbers';

export default {
  name: 'ModalUpdateCreditRequest',
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  props: {
    modal_data: {
      type: Object,
      required: true,
      default: null
    },
    handlerModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      modalController: false,
      data: {
      },
    };
  },
  computed: {
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    },
  },
  methods: {
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    handleClose() {
      this.$emit('change', false);
    },
    handleOpen() {
      this.data = this.modal_data.data
    },
  },
};
</script>

<style scoped>
.btn-space {
  margin-top: 24px
}
.icon-size-driver {
  width: 12px;
  text-align: center;
}
</style>
