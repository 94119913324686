<template>
  <b-modal
    id="bulkTedStatus"
    lazy
    no-fade
    size="lg"
    no-enforce-focus
    title="Status Aprovação TED"
    :cancel-title="$t('payments.buttons.close')"
    v-model="cpHandlerModal"
    @show="handleOpen"
    @hidden="handleClose"
  >
    <b-list-group>
      <b-list-group-item 
        v-for="(ted, index) in modal_data"
        :key="index"
        class="d-flex justify-content-between align-items-center"
      >
        <strong> Motorista: </strong>
        <b-link
          :href="`/drivers/profile/${ted.driver.id}`"
          target="_blank"
          :title="$t('payments.labels.goToDriver')"
        >
          <i class="fa fa-external-link icon-size-driver" />
          {{ ted.driver.name }}
        </b-link>
        <strong> Valor: </strong>{{ setPriceFormat($t('payments.labels.currency'), ted.amount) }}
        <b-badge variant="success" v-if="ted.success" pill>OK</b-badge>
        <b-badge variant="danger" v-else pill>Erro - {{ ted.error }}</b-badge>
      </b-list-group-item>
    </b-list-group>

    <div slot="modal-footer">
      <b-button
        v-b-tooltip.hover
        variant="success"
        class="ml-2"
        title="OK"
        @click.stop="handleClose"
      >
        OK
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { formatPrice } from '@utils/numbers';

export default {
  name: 'ModalBulkTedStatus',
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  props: {
    modal_data: {
      type: Array,
      required: true,
      default: null
    },
    handlerModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      modalController: false,
      data: {
      },
    };
  },
  computed: {
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    },
  },
  methods: {
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
    handleClose() {
      this.$emit('change', false);
    },
    handleOpen() {
      this.data = this.modal_data.data
    },
  },
};
</script>

<style scoped>
.btn-space {
  margin-top: 24px
}
.icon-size-driver {
  width: 12px;
  text-align: center;
}
</style>
